import { Invitation } from '../../types';
import {
  APIInvitation,
  APIResponse,
} from '../fetchers/organizationInvitations';

type MappedResponse = {
  accepted: Invitation[];
  expired: Invitation[];
  pending: Invitation[];
  revoked: Invitation[];
};

const convertToInvitation = (apiInvitation: APIInvitation): Invitation => ({
  id: apiInvitation.id,
  role: apiInvitation.role,
  email: apiInvitation.email_address,
  targetType: apiInvitation.target_type,
  targetId: apiInvitation.target_id,
  supersededBy: apiInvitation.superseded_by as string,
  isExpired: apiInvitation.is_expired,
  isAccepted: apiInvitation.is_accepted,
  isRevoked: apiInvitation.is_revoked,
  expiryDate: apiInvitation.expiry_date,
  createdAt: apiInvitation.created_at,
  inviterId: apiInvitation.inviter_id,
});

const mapInvitations = (response: APIResponse): MappedResponse => {
  const {
    accepted_invitations: apiAccepted,
    expired_invitations: apiExpired,
    pending_invitations: apiPending,
    revoked_invitations: apiRevoked,
  } = response;
  return {
    accepted: apiAccepted.map(convertToInvitation),
    expired: apiExpired.map(convertToInvitation),
    pending: apiPending.map(convertToInvitation),
    revoked: apiRevoked.map(convertToInvitation),
  };
};

export default mapInvitations;
