import { client } from '@circleci/web-ui-data';

type Fetch = typeof fetch;

type RemoveMemberFromOrgAndProjects = {
  fetch: Fetch;
  apiKey?: string;
  memberId: string;
  orgId: string;
};

const removeMemberFromOrgAndProjects = ({
  fetch,
  apiKey,
  memberId,
  orgId,
}: RemoveMemberFromOrgAndProjects) => {
  const pathname = `/private/ciam/orgs/${orgId}/role-grants/user/${memberId}`;

  return client
    .apiMutator({
      apiKey,
      fetch,
      pathname,
      method: 'DELETE',
      hostname: process.env.NEXT_PUBLIC_CIRCLECI_BFF_HOSTNAME,
    })
    .then((resp) => resp.text());
};

export default removeMemberFromOrgAndProjects;
