import * as R from 'ramda';

import { Context, ContextSecurityGroup } from '~/org-settings/graphql/types';
import {
  APIServiceOrganizationContextEdge,
  APIServiceGroupEdge,
} from '~/org-settings/graphql/shim/apiServiceTypes';

const mapSecurityGroup = ({
  node: { id, name },
}: APIServiceGroupEdge): ContextSecurityGroup => ({
  id,
  name,
});

const mapContext = ({
  node: { id, name, createdAt, groups },
}: APIServiceOrganizationContextEdge): Context => ({
  id,
  name,
  createdAt,
  securityGroups: R.map(mapSecurityGroup, groups.edges),
});

const mapContexts = R.pipe(
  R.path(['organization', 'contexts', 'edges']) as any,
  R.map(mapContext),
);

export default mapContexts;
