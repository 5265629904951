import gql from 'graphql-tag';

import apiServiceClient from '~/graphql/apiServiceClient';
import { APIServiceCreateContextPayload } from '~/org-settings/graphql/shim/apiServiceTypes';

const mutation = gql`
  mutation CreateContext($input: CreateContextInput!) {
    createContext(input: $input) {
      error {
        type
      }
    }
  }
`;

export interface Data {
  createContext: APIServiceCreateContextPayload;
}

interface Props {
  orgId: string;
  name: string;
}

const createContext = async ({ orgId, name }: Props) => {
  const result = await apiServiceClient.mutate<Data>({
    mutation,
    variables: {
      input: {
        ownerId: orgId,
        ownerType: 'ORGANIZATION',
        contextName: name,
      },
    },
  });
  return result.data;
};

export default createContext;
