import { APIOrganization } from '~/runners/graphql/shim/fetchers/organization';
import { Organization } from '~/runners/graphql/types';

const mapOrganization = ({ id, name, slug }: APIOrganization): Organization => {
  return {
    id,
    name,
    slug,
  };
};

export default mapOrganization;
