import { client } from '@circleci/web-ui-data';

type Fetch = typeof fetch;

const pathname = `/private/access/invitations`;

const sendInvitations = ({
  fetch,
  apiKey,
  emails,
  orgId,
  role,
}: {
  fetch: Fetch;
  apiKey?: string;
  emails: Array<string>;
  orgId: string;
  role: string;
}) =>
  client
    .apiMutator({
      apiKey,
      fetch,
      pathname,
      method: 'POST',
      hostname: process.env.NEXT_PUBLIC_CIRCLECI_BFF_HOSTNAME,
      body: {
        emails,
        role,
        target_id: orgId,
        target_type: 'org',
      },
    })
    .then((resp) => resp.text());

export default sendInvitations;
