import fetchAPIServiceOrbDetails, {
  OrbDetailsProps,
} from '~/onboarding/vcs/graphql/shim/fetchers/apiServiceOrbDetails';
import fetchAPIConfig from '~/onboarding/vcs/graphql/shim/fetchers/config';
import fetchAPIOrgs from '~/onboarding/vcs/graphql/shim/fetchers/organization';
import fetchAPIPipelineByNumber from '~/onboarding/vcs/graphql/shim/fetchers/pipelineByNumber';
import mapAPIOrg from '~/onboarding/vcs/graphql/shim/mappers/organization';
import updateConfig from '~/onboarding/vcs/graphql/shim/mutators/updateConfig';
import updateNewUIOptOut from '~/onboarding/vcs/graphql/shim/mutators/updateNewUIOptOut';
import { VCSType } from '~/onboarding/vcs/graphql/shim/types';

import fetchCreditsAvailable, {
  FetchCreditsAvailable,
} from './fetchers/creditsAvailable';
import fetchNumberOfUsers, {
  FetchNumberOfUsers,
} from './fetchers/numberOfUsers';
import mapCreditsAvailable from './mappers/creditsAvailable';
import mapNumberOfUsers from './mappers/numberOfUsers';

export interface ConfigProps {
  pipelineNumber: string;
  vcsType: VCSType;
  orgName: string;
  projectName: string;
}

interface Deps {
  apiKey: string;
  fetch: typeof window.fetch;
}

interface UpdateConfigProps {
  vcsType: VCSType;
  org: string;
  name: string;
  config: string;
  branchName: string;
  commitMessage: string;
  commitSha: string;
}

export interface FollowRepoInput {
  vcsType: VCSType;
  org: string;
  name: string;
}

export interface LatestConfigProps {
  vcsType: VCSType;
  orgName: string;
  projectName: string;
  branchName: string;
}
interface UpdateNewUIOptOutProps {
  optOut: string;
}

const resolvers = {
  Query: {
    projectPipelineConfig: async (
      _root: any,
      { pipelineNumber, vcsType, orgName, projectName }: ConfigProps,
      { fetch, apiKey }: Deps,
    ) => {
      const pipeline = await fetchAPIPipelineByNumber({
        fetch,
        apiKey,
        pipelineNumber,
        vcsType,
        orgName,
        projectName,
      });

      const apiConfig = await fetchAPIConfig(fetch, apiKey, pipeline.id);
      // vcs.origin_repository_url and vcs.target_repository_url values in the pipeline API response
      // will be different if the PR involves a fork/parent relationship (either direction)
      return {
        ...apiConfig,
        originUrl: pipeline.vcs.origin_repository_url,
        targetUrl: pipeline.vcs.target_repository_url,
      };
    },
    organizations: async (
      _root: unknown,
      _params: unknown,
      { fetch, apiKey }: Deps,
    ) => {
      const apiOrgs = await fetchAPIOrgs(fetch, apiKey);
      return apiOrgs
        .map(mapAPIOrg)
        .sort((a, b) =>
          a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1,
        );
    },
    orbDetails: async (_root: any, props: OrbDetailsProps) =>
      await fetchAPIServiceOrbDetails(props),
    creditsAvailable: async (_root: unknown, args: FetchCreditsAvailable) => {
      const data = await fetchCreditsAvailable(args);
      return mapCreditsAvailable(data);
    },
    numberOfUsers: async (_root: unknown, args: FetchNumberOfUsers) => {
      const data = await fetchNumberOfUsers(args);
      return mapNumberOfUsers(data);
    },
  },

  Mutation: {
    updateConfig: async (
      _root: any,
      {
        vcsType,
        org,
        name,
        config,
        branchName,
        commitMessage,
        commitSha,
      }: UpdateConfigProps,
      { fetch, apiKey }: Deps,
    ) => {
      await updateConfig(
        fetch,
        apiKey,
        vcsType,
        org,
        name,
        config,
        branchName,
        commitMessage,
        commitSha,
      );
    },
    updateNewUIOptOut: async (
      _root: any,
      props: UpdateNewUIOptOutProps,
      deps: Deps,
    ) => {
      await updateNewUIOptOut({ ...deps, ...props });
    },
  },
};

export default resolvers;
