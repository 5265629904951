import { Data } from '~/org-settings/graphql/shim/mutators/storeEnvironmentVariable';
import { StoreEnvironmentVariableError } from '~/org-settings/graphql/types';
import {
  APIServiceStoreEnvironmentVariablePayloadErrorType as APIErrorType,
  APIServiceStoreEnvironmentVariablePayloadError as APIError,
} from '~/org-settings/graphql/shim/apiServiceTypes';
import exceptionLogger from '~/org-settings/backplane/exceptionLogger';

const isAlreadyExistsError = (error: APIError) => {
  if (!error) return false;
  return error.type === APIErrorType.ALREADY_EXISTS;
};

const isInvalidArgumentError = (error: APIError) => {
  if (!error) return false;
  return error.type === APIErrorType.INVALID_ARGUMENT;
};

const logUnknownError = (data: Data) => {
  exceptionLogger.warn(
    'Uknown error from api-service on storeEnvironmentVariable',
    {
      data,
    },
  );
};

const storeEnvironmentVariable = (error: any) => {
  if (isAlreadyExistsError(error.storeEnvironmentVariable.error)) {
    return {
      errors: [StoreEnvironmentVariableError.ALREADY_EXISTS],
      message: error.storeEnvironmentVariable?.error?.message,
    };
  }

  if (isInvalidArgumentError(error.storeEnvironmentVariable.error)) {
    return {
      errors: [StoreEnvironmentVariableError.INVALID_ARGUMENT],
      message: error.storeEnvironmentVariable?.error?.message,
    };
  }
  if (error.storeEnvironmentVariable.error) {
    logUnknownError(error);
    return {
      errors: [StoreEnvironmentVariableError.UNKNOWN],
    };
  }
  return { errors: [] };
};

export default storeEnvironmentVariable;
