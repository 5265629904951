import { client } from '@circleci/web-ui-data';

export interface APIApplication {
  id: string;
  name: string;
  description?: string | null;
}

export interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
  id: string;
}

const fetchApplication = async ({
  apiKey,
  fetch,
  id,
}: Props): Promise<APIApplication> => {
  const pathname = `api/private/application/${id}`;
  const response = await client.apiFetcher({ apiKey, fetch, pathname });
  return await response.json();
};

export default fetchApplication;
