import { APIOrbsList, APIOrbStatistics } from '../fetchers/orbs';
import { OrbList, OrbStatistics } from '../../types';

const mapStatistics = ({
  last_30_days_build_count,
  last_30_days_project_count,
  last_30_days_organization_count,
}: APIOrbStatistics): OrbStatistics => ({
  last30DaysBuildCount: last_30_days_build_count,
  last30DaysProjectCount: last_30_days_project_count,
  last30DaysOrganizationCount: last_30_days_organization_count,
});

const mapOrbs = ({ orbs, next_page_token }: APIOrbsList): OrbList => {
  const mappedOrbs = orbs.map(
    ({
      orb_id,
      orb_name,
      created_at,
      latest_version_number,
      description,
      namespace_name,
      is_private,
      statistics,
      ...rest
    }) => ({
      orbId: orb_id,
      orbName: orb_name,
      createdAt: created_at,
      latestVersionNumber: latest_version_number,
      description: description,
      namespaceName: namespace_name,
      isPrivate: is_private,
      statistics: mapStatistics(statistics),
      ...rest,
    }),
  );
  return { orbs: mappedOrbs, nextPageToken: next_page_token };
};

export default mapOrbs;
