import * as R from 'ramda';
import {
  APIServiceContextData,
  APIServiceGroupEdge,
} from '~/org-settings/graphql/shim/apiServiceTypes';
import {
  ContextDetails,
  ContextSecurityGroup,
} from '~/org-settings/graphql/types';

const mapSecurityGroup = ({
  node: { id, name },
}: APIServiceGroupEdge): ContextSecurityGroup => ({
  id,
  name,
});

const availableSecurityGroups = (
  contextGroups: APIServiceGroupEdge[],
  orgGroups: APIServiceGroupEdge[],
) => {
  if (R.isNil(orgGroups)) return null;
  const groups = R.difference(orgGroups, contextGroups);
  return R.map(mapSecurityGroup, groups);
};

const mapContext = ({ context }: APIServiceContextData): ContextDetails => ({
  environmentVariables: context.resources.map((resources) => ({
    truncatedValue: resources.truncatedValue,
    name: resources.variable,
    createdAt: resources.createdAt,
    updatedAt: resources.updatedAt,
  })),
  securityGroups: R.map(mapSecurityGroup, context.groups.edges),
  name: context.name,
  availableSecurityGroups: availableSecurityGroups(
    R.path(['groups', 'edges'], context) as APIServiceGroupEdge[],
    R.path(['owner', 'groups', 'edges'], context) as APIServiceGroupEdge[],
  ),
});

export default mapContext;
