import { APIOrgSettings } from '~/org-settings/graphql/shim/fetchers/orgSettings';

const uncertifiedOrbsAllowed = (settings: APIOrgSettings) => {
  if (settings.feature_flags) {
    return !!settings.feature_flags.uncertified_orbs_allowed;
  } else {
    return settings['uncertified_orbs_allowed?'];
  }
};

const acceptedRunnerTermsOfService = (settings: APIOrgSettings) => {
  if (settings.feature_flags) {
    return !!settings.feature_flags.accepted_runner_terms_of_service;
  } else {
    return null;
  }
};

export default (settings: APIOrgSettings) => ({
  uncertifiedOrbsAllowed: uncertifiedOrbsAllowed(settings),
  acceptedRunnerTermsOfService: acceptedRunnerTermsOfService(settings),
  aiErrorSummarization: settings.feature_flags?.ai_error_summarization ?? null,
  allowApiTriggerWithConfig:
    settings.feature_flags?.allow_api_trigger_with_config ?? null,
  allowCertifiedPublicOrbs:
    settings.feature_flags?.allow_certified_public_orbs ?? null,
  allowUncertifiedPublicOrbs:
    settings.feature_flags?.allow_uncertified_public_orbs ?? null,
  allowPrivateOrbs: settings.feature_flags?.allow_private_orbs ?? null,
  bitbucketWorkspaceMemberIsOrgMember:
    settings.feature_flags?.bitbucket_workspace_member_is_org_member ?? null,
});
