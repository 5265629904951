import React, { createContext, useContext } from 'react';

import { useAllowed, useOrgContext } from '@circleci/web-ui-data';
import getConfig from 'next/config';

import useRunnerTC from '~/runners/hooks/shared/useRunnerTC';

interface RunnerContext {
  runnerEnabled: boolean;
  canManageOrg: boolean;
  orgID: string | undefined;
}

export const TCContext = createContext<RunnerContext>({
  runnerEnabled: false,
  canManageOrg: false,
  orgID: undefined,
});
export const useRunnerTCContext = () => {
  return useContext(TCContext);
};

const TCContextProvider: React.FC = ({ children }) => {
  const { publicRuntimeConfig } = getConfig();
  const { orgId: orgID, vcsType, name: orgName } = useOrgContext();
  /**
   * Determines whether a user is an admin of the org.
   * Only admins can create resource classes.
   */
  const { isAllowed: canManageOrg } = useAllowed(
    { action: 'manage-org', objectId: orgID },
    { ready: !!orgID, hostname: publicRuntimeConfig.bffDomain },
  );

  const { runnerEnabled } = useRunnerTC({
    vcsType: vcsType as any,
    orgName: orgName as string,
  });
  return (
    <TCContext.Provider value={{ runnerEnabled, canManageOrg, orgID }}>
      {children}
    </TCContext.Provider>
  );
};

export default TCContextProvider;
