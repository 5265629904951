import { client } from '@circleci/web-ui-data';

type Fetch = typeof fetch;

export const updateAllowUncertifiedPublicOrbs = async ({
  fetch,
  apiKey,
  vcsType,
  orgName,
  allowUncertifiedPublicOrbs,
}: {
  fetch: Fetch;
  apiKey?: string;
  vcsType: string;
  orgName: string;
  allowUncertifiedPublicOrbs: boolean;
}) => {
  const pathname = `/api/v1.1/organization/${vcsType}/${orgName}/settings`;
  const response = await client.apiMutator({
    apiKey,
    fetch,
    pathname,
    method: 'PUT',
    body: {
      feature_flags: {
        'allow-uncertified-public-orbs': allowUncertifiedPublicOrbs,
      },
    },
  });
  return await response.json();
};
