import { client } from '@circleci/web-ui-data';

import { VCSType } from '~/onboarding/vcs/graphql/shim/types';

type Fetch = typeof fetch;

const updateConfig = (
  fetch: Fetch,
  apiKey: string | undefined,
  vcsType: VCSType,
  org: string,
  project: string,
  config: string,
  branchName: string,
  commitMessage: string,
  commitSha: string,
): Promise<void> => {
  const pathname = `/api/v1.1/project/${vcsType}/${org}/${project}/config`;

  return client.apiMutator({
    apiKey,
    fetch,
    pathname,
    body: { content: config, message: commitMessage, sha: commitSha },
    queryStringParams: {
      branch: branchName,
    },
  });
};

export default updateConfig;
