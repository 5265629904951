import { client } from '@circleci/web-ui-data';

export type APIMember = {
  user_id: string; // uuid
  email: string;
  role: string;
  username: string;
  avatar_url: string;
};

type Fetch = typeof fetch;

type FetchMembersParams = {
  fetch: Fetch;
  apiKey?: string;
  orgId: string;
};

const fetchOrgMembers = async ({
  fetch,
  apiKey,
  orgId,
}: FetchMembersParams): Promise<APIMember[]> => {
  const pathname = `/private/ciam/orgs/${orgId}/role-grants`;
  const response = await client.apiFetcher({
    apiKey,
    fetch,
    pathname,
    urlOptions: {
      hostname: process.env.NEXT_PUBLIC_CIRCLECI_BFF_HOSTNAME,
    },
  });

  const body = await response.json();
  return (body.items || []) as APIMember[];
};

export default fetchOrgMembers;
