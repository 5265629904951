import { ManageableOrgRoles } from '../../types';

interface APIResult {
  can_manage: string[];
}

const mapManageableRoles = (roles: APIResult): ManageableOrgRoles => ({
  canManage: roles.can_manage,
});

export default mapManageableRoles;
