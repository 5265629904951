import { APIService } from '~/runners/graphql/shim/fetchers/services';
import { Service } from '~/runners/graphql/types';

export const mapService = ({ id, name, description }: APIService): Service => {
  return {
    id,
    name,
    description: description ?? null,
  };
};

const mapServices = (services: APIService[]): Service[] => {
  return services.map(mapService);
};

export default mapServices;
