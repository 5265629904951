import gql from 'graphql-tag';

import apiServiceClient from '~/graphql/apiServiceClient';
import { APIServiceDeleteContextPayload } from '~/org-settings/graphql/shim/apiServiceTypes';

const mutation = gql`
  mutation DeleteContext($input: DeleteContextInput!) {
    deleteContext(input: $input) {
      clientMutationId
    }
  }
`;

export interface Data {
  deleteContext: APIServiceDeleteContextPayload;
}

interface Props {
  contextId: string;
}

const deleteContext = async ({ contextId }: Props) => {
  const result = await apiServiceClient.mutate<Data>({
    mutation,
    variables: {
      input: {
        contextId,
      },
    },
  });
  return result?.data?.deleteContext;
};

export default deleteContext;
