import gql from 'graphql-tag';

import apiServiceClient from '~/graphql/apiServiceClient';
import {
  APIServiceOrganizationExternalLinksData,
  APIServiceOrganizationExternalLinks,
} from '~/org-settings/graphql/shim/apiServiceTypes';

const query = gql`
  query OrganizationGitHubChecks($orgId: ID!) {
    organization(id: $orgId) {
      id
      externalLinks {
        githubChecksInstall
      }
    }
  }
`;

interface Props {
  orgId: string;
}

export default async (
  variables: Props,
): Promise<APIServiceOrganizationExternalLinks> => {
  const result =
    await apiServiceClient.query<APIServiceOrganizationExternalLinksData>({
      query,
      variables,
    });
  return result.data.organization;
};
