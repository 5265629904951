import { client } from '@circleci/web-ui-data';

export type APIResponse = {
  accepted_invitations: APIInvitation[];
  expired_invitations: APIInvitation[];
  pending_invitations: APIInvitation[];
  revoked_invitations: APIInvitation[];
  target_id: string; // uuid
  target_type: 'org';
};

export type APIInvitation = {
  id: string; // uuid
  inviter_id: string; // uuid
  created_at: string; // timestamp
  expiry_date: string; // timestamp
  email_address: string;
  is_accepted: boolean;
  is_expired: boolean;
  is_revoked: boolean;
  superseded_by: null | string;
  accepted_by: null | string;
  target_id: string;
  target_type: 'org' | string;
  role: string;
};

type Fetch = typeof fetch;

type FetchInvitationParams = {
  fetch: Fetch;
  apiKey?: string;
  orgId: string;
};

const fetchInvitation = async ({
  fetch,
  apiKey,
  orgId,
}: FetchInvitationParams): Promise<APIResponse> => {
  const pathname = `/private/access/invitations/org/${orgId}`;
  const response = await client.apiFetcher({
    apiKey,
    fetch,
    pathname,
    urlOptions: {
      hostname: process.env.NEXT_PUBLIC_CIRCLECI_BFF_HOSTNAME,
    },
  });
  return await response.json();
};

export default fetchInvitation;
