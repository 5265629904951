import { client } from '@circleci/web-ui-data';

type Fetch = typeof fetch;

const changeRole = ({
  fetch,
  apiKey,
  orgId,
  usrId,
  role,
}: {
  fetch: Fetch;
  apiKey?: string;
  orgId: string;
  usrId: string;
  role: string;
}) => {
  const pathname = `/private/ciam/orgs/${orgId}/role-grants/user/${usrId}`;

  const response = client
    .apiMutator({
      apiKey,
      fetch,
      pathname,
      method: 'PUT',
      hostname: process.env.NEXT_PUBLIC_CIRCLECI_BFF_HOSTNAME,
      body: {
        role,
      },
    })
    .then((resp) => resp.text());

  return response;
};

export default changeRole;
