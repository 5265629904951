import { client } from '@circleci/web-ui-data';

const pathname = '/api/v1/user/save-preferences';

interface Props {
  apiKey?: string;
  fetch: typeof window.fetch;
  optOut: string;
}

const updateNewUIOptOut = async ({ apiKey, fetch, optOut }: Props) => {
  const body = { web_ui_pipelines_optout: optOut };
  await client.apiMutator({ apiKey, fetch, pathname, method: 'PUT', body });
};

export default updateNewUIOptOut;
