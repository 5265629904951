import { client } from '@circleci/web-ui-data';

interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
}

export interface APIService {
  id: string;
  name: string;
  description?: string | null;
}

const fetchServices = async ({
  apiKey,
  fetch,
}: Props): Promise<APIService[]> => {
  const pathname = 'api/private/service';
  const response = await client.apiFetcher({ apiKey, fetch, pathname });
  return await response.json();
};

export default fetchServices;
