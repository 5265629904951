import { APIPipeline } from '~/runners/graphql/shim/fetchers/pipeline';
import { Pipeline } from '~/runners/graphql/types';

const mapActor = ({
  login,
  avatar_url,
}: APIPipeline['trigger']['actor']): Pipeline['trigger']['actor'] => {
  return {
    login,
    avatarUrl: avatar_url ?? null,
  };
};

const mapTrigger = ({
  type,
  received_at,
  actor,
}: APIPipeline['trigger']): Pipeline['trigger'] => {
  return {
    type,
    receivedAt: received_at,
    actor: mapActor(actor),
  };
};

export const buildCommitUrl = ({
  vcsType,
  repositoryUrl,
  revision,
}: {
  vcsType: string;
  repositoryUrl: string;
  revision: string;
}): string => {
  return vcsType.toLocaleLowerCase() === 'github'
    ? `${repositoryUrl}/commit/${revision}`
    : `${repositoryUrl}/commits/${revision}`;
};

const mapVCS = ({
  provider_name,
  target_repository_url,
  branch,
  review_id,
  review_url,
  revision,
  tag,
  commit,
  origin_repository_url,
}: APIPipeline['vcs']): Pipeline['vcs'] => {
  return {
    providerName: provider_name,
    targetRepositoryUrl: target_repository_url,
    branch,
    reviewId: review_id ?? null,
    reviewUrl: review_url ?? null,
    revision,
    tag: tag ?? null,
    commit: {
      subject: commit.subject,
      body: commit.body,
      url: buildCommitUrl({
        vcsType: provider_name,
        repositoryUrl: origin_repository_url,
        revision,
      }),
    },
    originRepositoryUrl: origin_repository_url,
  };
};

const mapPipeline = ({
  id,
  errors,
  project_slug,
  updated_at,
  number,
  state,
  created_at,
  trigger,
  vcs,
}: APIPipeline): Pipeline => {
  return {
    id,
    errors,
    projectSlug: project_slug,
    updatedAt: updated_at,
    number,
    createdAt: created_at,
    state,
    trigger: mapTrigger(trigger),
    vcs: mapVCS(vcs),
  };
};

export default mapPipeline;
