export interface APIServiceGroupEdge {
  node: {
    id: string;
    name: string;
  };
}

export interface APIServiceOrganizationContextEdge {
  node: {
    id: string;
    name: string;
    createdAt: string;
    groups: {
      edges: APIServiceGroupEdge[];
    };
  };
}

export interface APIServiceOrganizationExternalLinks {
  externalLinks: {
    githubChecksInstall: string;
  };
}

export interface APIServiceOrganizationExternalLinksData {
  organization: APIServiceOrganizationExternalLinks;
}
export interface APIServiceOrganization {
  id?: string;
  contexts?: {
    edges: APIServiceOrganizationContextEdge[];
  };
}

export interface APIServiceOrganizationData {
  organization: APIServiceOrganization;
}

export interface APIServiceOrganizationWithGroup {
  id?: string;
  groups?: {
    edges: {
      node: {
        id: string;
        name: string;
      };
    }[];
  };
}

export enum APIServiceCreateContextPayloadErrorType {
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  INVALID_ARGUMENT = 'INVALID_ARGUMENT',
  PERMISSION_DENIED = 'PERMISSION_DENIED',
}

export interface APIServiceCreateContextPayloadError {
  type: APIServiceCreateContextPayloadErrorType;
}

export interface APIServiceCreateContextPayload {
  error: APIServiceCreateContextPayloadError;
}

export interface APIServiceDeleteContextPayload {
  clientMutationId: string | null;
}

export interface APIServiceRemoveEnvironmentVariablePayload {
  clientMutationId: string | null;
}

export interface APIServiceRemoveFromAllowedContextGroupsPayload {
  context: {
    id: string;
  };
}
export interface APIServiceResource {
  createdAt: string;
  updatedAt: string;
  truncatedValue: string;
  variable: string;
}

export interface APIServiceGroup {
  edges: Array<APIServiceGroupEdge>;
}

export interface APIServiceContext {
  resources: Array<APIServiceResource>;
  groups: APIServiceGroup;
  name: string;
  owner: APIServiceOrganizationWithGroup;
}

export interface APIServiceContextData {
  context: APIServiceContext;
}

export enum APIServiceStoreEnvironmentVariablePayloadErrorType {
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  INVALID_ARGUMENT = 'INVALID_ARGUMENT',
}

export interface APIServiceStoreEnvironmentVariablePayloadError {
  type: APIServiceStoreEnvironmentVariablePayloadErrorType;
}

export interface APIServiceStoreEnvironmentVariablePayload {
  error: APIServiceStoreEnvironmentVariablePayloadError | null;
}

export interface AddToAllowedContextGroupsPayload {
  context: {};
}
