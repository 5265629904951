import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import {
  useState,
  createContext,
  FC,
  useEffect,
  PropsWithChildren,
} from 'react';

import getHostname from '~/onboarding/utils/getHostname';
import getIsEnterprise from '~/onboarding/utils/getIsEnterprise';

export interface SurveyDataResponse {
  user_analytics_id?: string | null;
  role?: string;
  use_case: string;
  eng_size: string;
  purpose?: string;
  ai_product: string;
}

const UserSurveyContext = createContext<{
  data?: SurveyDataResponse;
  hasAnswered: boolean;
  isLoading: boolean;
  hasFailed: boolean;
  setData: (data: SurveyDataResponse) => void;
}>({
  isLoading: false,
  hasFailed: false,
  hasAnswered: false,
  setData: (_: SurveyDataResponse) => void {},
});

export const UserSurveyProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  // Skipping fetching survey data in Synthetics to avoid polluting datadog
  // with 409s caused by the fact that token based auth isn't supported by this
  // endpoint.
  const isDataDogSyntheticsBot =
    !!window.navigator.userAgent.match(/DatadogSynthetics/i);
  const skipFetchingSurveyData = getIsEnterprise() || isDataDogSyntheticsBot;

  const [data, setData] = useState<SurveyDataResponse>();

  const {
    isInitialLoading: isLoading,
    data: fetchedData,
    error,
  } = useQuery(
    ['getUserSurvey'],
    async () => {
      // To avoid blocking user from entering app, abort fetch call if response isn't received within 5 seconds
      const controller = new AbortController();
      const signal = controller.signal;
      const id = setTimeout(() => {
        controller.abort();
        throw new Error('/user-attributes request timed out');
      }, 5000);

      const URL = `https://bff.${getHostname()}/private/onboarding-service/user-attributes`;

      let response: Response;
      try {
        response = await fetch(URL, {
          credentials: 'include',
          signal,
        });
      } catch (err) {
        clearTimeout(id);
        throw err;
      }

      clearTimeout(id);

      if (response.status === 200) {
        const data = await response.json();
        return data as SurveyDataResponse;
      }

      // 404 here means that user record not found. Resolving to empty object will cause user attribute modal to render.
      if (response.status === 404) {
        return null;
      }

      throw new Error('Error Fetching /user-attributes data');
    },
    {
      enabled: !skipFetchingSurveyData,
      retry: false,
    },
  );

  useEffect(() => {
    if (fetchedData) {
      setData(fetchedData);
    }
  }, [fetchedData]);

  return (
    <UserSurveyContext.Provider
      value={{
        isLoading,
        data: data || undefined,
        hasAnswered: !isEmpty(data) || fetchedData !== null,
        hasFailed: !!error,
        setData,
      }}
    >
      {children}
    </UserSurveyContext.Provider>
  );
};

export const UserSurveyConsumer = UserSurveyContext.Consumer;
export default UserSurveyContext;
