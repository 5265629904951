import { gql } from '@apollo/client';

import apiServiceClient from '~/onboarding/vcs/graphql/shim/apiServiceClient';

export const query = gql`
  query OrbDetailsQuery($fullNameWithVersion: String) {
    orbVersion(orbVersionRef: $fullNameWithVersion) {
      createdAt
      id
      notes
      source
      version
    }
  }
`;

export interface OrbDetailsProps {
  name: string;
  namespace: string;
  version: string;
}

export interface OrbDetailsData {
  orbVersion: {
    created_at: string;
    id: string;
    source: string;
    version: string;
  };
}

const getOrbDetails = async ({ name, namespace, version }: OrbDetailsProps) => {
  const fullNameWithVersion = `${namespace}/${name}@${version}`;
  const result = await apiServiceClient.query<OrbDetailsData>({
    query,
    variables: { fullNameWithVersion },
  });

  return result.data.orbVersion;
};

export default getOrbDetails;
