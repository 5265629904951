import gql from 'graphql-tag';

import apiServiceClient from '~/graphql/apiServiceClient';
import { APIServiceStoreEnvironmentVariablePayload } from '~/org-settings/graphql/shim/apiServiceTypes';

const mutation = gql`
  mutation StoreEnvironmentVariable($input: StoreEnvironmentVariableInput!) {
    storeEnvironmentVariable(input: $input) {
      error {
        type
        message
      }
    }
  }
`;

export interface Data {
  storeEnvironmentVariable: APIServiceStoreEnvironmentVariablePayload;
}

interface Props {
  contextId: string;
  value?: string;
  name: string;
}

const storeEnvironmentVariable = async ({
  name,
  value = '',
  contextId,
}: Props) => {
  const result = await apiServiceClient.mutate<Data>({
    mutation,
    variables: {
      input: {
        contextId,
        variable: name,
        value,
      },
    },
  });

  return result.data;
};

export default storeEnvironmentVariable;
