import { client } from '@circleci/web-ui-data';

// https://circleci.com/docs/api/v2/#operation/getJobDetails
// Partial set of fields since we don't (currently) care about most of the fields returned
export interface APIJob {
  project: {
    slug: string;
    name: string;
    external_url: string;
  };
  name: string;
  number: number;
  web_url: string;
}

export interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
  projectSlug: string;
  jobNumber: string | number;
}

const fetchJob = async ({
  apiKey,
  fetch,
  projectSlug,
  jobNumber,
}: Props): Promise<APIJob> => {
  const pathname = `/api/v2/project/${projectSlug}/job/${jobNumber}`;
  const response = await client.apiFetcher({ apiKey, fetch, pathname });
  return await response.json();
};

export default fetchJob;
