import { client } from '@circleci/web-ui-data';

export type APIResponse = {
  can_manage: string[];
};

type Fetch = typeof fetch;

type FetchManageableOrgRolesParams = {
  fetch: Fetch;
  apiKey?: string;
  orgId: string;
};

const fetchManageableOrgRoles = async ({
  fetch,
  apiKey,
  orgId,
}: FetchManageableOrgRolesParams): Promise<APIResponse> => {
  const pathname = `/private/ciam/orgs/${orgId}/manageable-roles`;
  const response = await client.apiFetcher({
    apiKey,
    fetch,
    pathname,
    urlOptions: {
      hostname: process.env.NEXT_PUBLIC_CIRCLECI_BFF_HOSTNAME,
    },
  });
  return response.json();
};

export default fetchManageableOrgRoles;
