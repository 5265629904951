const typeDefs = `
scalar DateTime

type User {
  avatarUrl: String
  id: ID
  name: String
  vcsUrl: String
}

type APIServiceUser {
  avatarURL: String
  id: ID
  name: String
}

type Config {
  compiled: String!
  source: String!
  originUrl: String!
  targetUrl: String!
  setup_config: String
  compiled_setup_config: String
}

type OrganizationVer2 {
  name: String!
  id: String!
}

type ProjectVer2 {
  slug: ID!
  name: String!
  organization: OrganizationVer2!
}

type LatestConfig {
  content: String!
  path: String!
  sha: String!
}

type ProjectInfo {
  exists: Boolean!
  analyticsId: String!
  hasFollowers: Boolean!
  permissions: ProjectPermissions!
}

type ProjectPermissions {
  triggerBuilds: Boolean!
  write: Boolean!
  approveJobs: Boolean!
}

type Job {
  usingNewStepsAPI: Boolean
  buildNumber: Int
  name: String
  cacheKey: ID!
  id: String
  startedAt: DateTime
  usageQueuedAt: DateTime
  queuedAt: DateTime
  done: Boolean!
  status: String!
  stoppedAt: DateTime
  buildUrl: String!
  parallelRuns: [ParallelRun!]!
  pipelinesEnabled: Boolean!
  actor: User!
  workflow: Workflow
  vcsUrl: String
  vcsRevision: String
  commits: [Commit!]!
  commitsTruncated: Boolean!
  why: String
  testSummary: TestSummary!
  branch: String
  pipeline: Pipeline
  messages: [JobMessage!]!
  resourceCpu: Int
  resourceRam: Float
  resourceClass: String
  resourceName: String
  resourceExecutor: String
  oss: Boolean!
  retries: [Int!]!
}

type LegacyJob {
  buildNumber: Int!
  name: String
  legacyName: String
  id: ID
  startedAt: DateTime
  usageQueuedAt: DateTime
  queuedAt: DateTime
  done: Boolean!
  status: String!
  stoppedAt: DateTime
  buildUrl: String!
  parallelRuns: [ParallelRun!]!
  actor: User!
  workflow: Workflow
  vcsUrl: String
  vcsRevision: String
  commits: [Commit!]
  commitsTruncated: Boolean!
  commitSubject: String
  why: String
  testSummary: TestSummary!
  branch: String
  messages: [JobMessage!]!
  resourceCpu: Int
  resourceRam: Float
  resourceClass: String
  resourceName: String
  resourceExecutor: String
  oss: Boolean!
  platform: String
}

type OrgJob {
  buildNumber: Int!
  name: String
  repoName: String
  legacyName: String
  id: ID
  startedAt: DateTime
  usageQueuedAt: DateTime
  queuedAt: DateTime
  done: Boolean!
  status: String!
  stoppedAt: DateTime
  buildUrl: String!
  parallelRuns: [ParallelRun!]!
  actor: User!
  workflow: Workflow
  vcsUrl: String
  vcsRevision: String
  commits: [Commit!]
  commitsTruncated: Boolean!
  commitSubject: String
  why: String
  testSummary: TestSummary!
  branch: String
  messages: [JobMessage!]!
  resourceCpu: Int
  resourceRam: Float
  resourceClass: String
  resourceName: String
  resourceExecutor: String
  platform: String
}

type TestSummaryException {
  output: [String!]!
}

type TestSummary {
  exceptions: [TestSummaryException!]
  exceptionCount: Int!
  failed: [TestResult!]!
  failedCount: Int!
  totalCount: Int!
  isSetup: Boolean!
}

type TestResult {
  className: String
  fileName: String
  message: String
  name: String
  result: String
  runTime: Float
  source: String
  sourceType: String
}

type ProjectBranches {
  items: [Branch!]!
  default: String
}

type Branch {
  id: ID!
  name: String!
}

type Commit {
  vcsRevision: String
  vcsUrl: String
}

type PipelineCommit {
  subject: String
}

enum JobMessageType {
  error
  info
  warning
  unknown
}

type JobMessage {
  type: JobMessageType!
  reason: String
  message: String!
}

type PlanV2 {
  tier: String
}

enum PipelineMessageType {
  config
  configFetch
  plan
  unknown
}

type PipelineMessage {
  type: PipelineMessageType!
  messages: [String!]!
}

type Workflow {
  id: ID!
  name: String!
  pullRequests: [PullRequest]
}

type JobRerunFailedTestsMetrics {
  id: ID!
  totalTestCount: Int!
  failedTestCount: Int!
  prevDuration: Int!
}

type WorkflowRerunFailedTestsMetrics {
  wasRerunFailedTests: Boolean!
  prevTestsDuration: Int!
  totalTestCount: Int!
  failedTestCount: Int!
  jobs: [JobRerunFailedTestsMetrics!]!
}

type PipelineWorkflow {
  id: ID!
  name: String!
  status: String!
  createdAt: String!
  stoppedAt: String
  tag: String
  rerunFailedTestsMetrics: WorkflowRerunFailedTestsMetrics
}

type ParallelRun {
  job: Job! # parent
  id: ID!
  slug: String!
  startedAt: DateTime
  status: String!
  steps: [ParallelRunStep!]!
  stoppedAt: DateTime
}

type PullRequest {
  headSHA: String
  url: String!
}

type ParallelRunStep {
  parallelRun: ParallelRun! # parent
  bashCommand: String
  id: ID!
  slug: String!
  stepIndex: Int!
  allocationId: String!
  insignificant: Boolean!
  name: String!
  runtime: Float
  status: String!
  startedAt: DateTime
  stoppedAt: DateTime
  exitCode: Float
}

type PolicyFailure {
  rule: String
  reason: String
}

type PolicyDecision {
  status: String
  enabledRules: [String]
  softFailures: [PolicyFailure]
  hardFailures: [PolicyFailure]
}

type TriggerParametersCircleCi {
  triggerType: String
  actorId: String
}

type TriggerParametersEvent {
  title: String
  description: String
}

type TriggerParametersGitHubApp {
  branch: String
  commitSha: String
  commitTitle: String
  ref: String
  repoFullName: String
  repoUrl: String
  userUsername: String
}

type TriggerParameters {
  circleci: TriggerParametersCircleCi!
  event: TriggerParametersEvent!
  githubApp: TriggerParametersGitHubApp
}

type Pipeline {
  id: ID!
  projectSlug: String!
  state: String
  number: String!
  createdAt: DateTime!
  workflows: [PipelineWorkflow!]!
  projectV2: ProjectVer2
  branch: String
  tag: String
  vcsRevision: String
  vcsUrl: String
  commit: PipelineCommit
  actor: User!
  messages: [PipelineMessage!]!
  triggerType: String
  policyDecision: PolicyDecision
  triggerParameters: TriggerParameters
}

type PipelineNumber {
  id: ID!
  number: String!
}

type Pipelines {
  items: [Pipeline!]!
  nextPageToken: String
}

type Repository {
  isNew: Boolean!
}

type WorkflowPipeline {
  id: ID!
  name: String!
  status: String
  createdAt: String!
  stoppedAt: String
  pipelineId: String
  pipelineNumber: String
  projectSlug: String!
  systemActor: User
}

type WorkflowActions {
  actions: [WorkflowGraphJobAction]
  buildNumber: Int
  id: ID!
}

type WorkflowGraphJobAction {
  actor: APIServiceUser!
  type: WorkflowGraphJobActionType!
}

enum WorkflowGraphJobActionType {
  APPROVE
  CANCEL
}

type WorkflowJob {
  id: ID!
  dependencies: [ID!]!
  name: String!
  buildNumber: Int
  status: String!
  startedAt: String
  stoppedAt: String
  type: String!
}

type RerunWithSshJob {
  buildNumber: Int
  newWorkflowId: String
}

type Collaboration {
  id: String
  name: String!
  avatarUrl: String
  vcsType: String!
  superAdmin: Boolean!
  slug: String
}

type SshJob {
  id: ID!
  buildNumber: Int!
  startedAt: String
  stoppedAt: String
  status: String!
}

type TriggeredPipeline {
  id: String!
  state: String!
  number: Int!
}

type JobProject {
  slug: String!
  name: String!
  externalUrl: String!
}

enum ReleaseStatus {
  PENDING
  RUNNING
  FINISHED
  FAILED
  UNKNOWN
}

enum ReleaseStepStatus {
  SUCCESS
  FAILED
  RUNNING
  UNKNOWN
}

type ReleasePolicy {
  id: String!
  name: String!
  description: String
}

type ReleaseStep {
  name: String!
  status: ReleaseStepStatus!
  startedAt: String
  endedAt: String
}

type Application {
  id: String!
  name: String!
  description: String
}

type Service {
  id: String!
  name: String!
  description: String
}

type DeploymentTrigger {
  pipelineId: ID
  workflowId: ID
  buildNumber: Int
  name: String
  url: String
}

type Release {
  id: ID!
  applicationId: ID!
  serviceId: ID!
  deploymentTrigger: DeploymentTrigger
  sourceVersion: String
  targetVersion: String!
  policyId: ID!
  steps: [ReleaseStep!]!
  status: ReleaseStatus!
  startedAt: String
  endedAt: String
}

type RunnersJob {
  project: JobProject!
  name: String!
  number: Int!
  webUrl: String!
}

type PipelineError {
  type: String!
  message: String!
}

type PipelineActor {
  login: String!
  avatarUrl: String
}

type PipelineTrigger {
  type: String!
  receivedAt: String!
  actor: PipelineActor!
}

type RunnersPipelineCommit {
  subject: String!
  body: String!
  url: String!
}

type PipelineVCS {
  providerName: String!
  targetRepositoryUrl: String!
  branch: String!
  reviewId: String
  reviewUrl: String
  revision: String!
  tag: String
  commit: RunnersPipelineCommit!
  originRepositoryUrl: String!
}

type RunnersPipeline {
  id: ID!
  errors: [PipelineError!]!
  projectSlug: String!
  updatedAt: String!
  number: Int!
  createdAt: String!
  state: String!
  trigger: PipelineTrigger!
  vcs: PipelineVCS!
}


type Query {
  config(pipelineId: String!): Config!
  collaborations: [Collaboration!]!
  job(vcsType: String!, username: String!, project: String!, buildNumber: ID!): Job!
  legacyJob(vcsType: String!, username: String!, project: String!, buildNumber: ID!): LegacyJob!
  legacyJobs(vcsType: String!, username: String!, project: String!, offset: Int): [LegacyJob!]!
  orgJobs(vcsType: String!, username: String!, offset: Int): [OrgJob!]!
  projectBranches(vcsType: String!, username: String!, project: String!): ProjectBranches!
  projectInfo(vcsType: String!, orgName: String!, projectName: String!): ProjectInfo!
  pipeline(vcsType: String!, orgName: String!, projectName: String!, pipelineNumber: String!): Pipeline!
  pipelineWithWorkflows(vcsType: String!, orgName: String!, projectName: String!, pipelineNumber: String!): Pipeline!
  pipelineNumber(pipelineId: String!): PipelineNumber!
  pipelines(vcsType: String!, username: String!, project: String, filter: String, branch: String, pageToken: String, authed: Boolean, createdBefore: DateTime, isBffV2Enabled: Boolean): Pipelines!
  planV2(vcsType: String!, orgName: String!): PlanV2
  stepOutput(vcsType: String!, username: String!, project: String!, buildNumber: ID!, parallelRunSlug: String!, stepSlug: String!, allocationId: String!): [StepOutput!]!
  testSummary(vcsType: String!, username: String!, project: String!, buildNumber: ID!): TestSummary!
  workflowJobs(workflowId: ID!): [WorkflowJob!]!
  artifacts(vcsType: String!, username: String!, project: String!, buildNumber: ID!): [Artifact]!
  workflow(workflowId: ID!): WorkflowPipeline!
  workflowActions(workflowId: ID!): [WorkflowActions]!
  sshJobs(vcsType: String!, orgName: String!, projectName: String!, buildNumber: Int!): [SshJob!]!
  latestConfig(vcsType: String!, orgName: String!, projectName: String!, branchName: String): LatestConfig
  application(id: ID!): Application!
  runnersJob(projectSlug: String!, jobNumber: String!): RunnersJob!
  runnersPipeline(id: ID!): RunnersPipeline!
  organization(vcsType: String!, orgName: String!): Organization!
  releases(organizationId: ID!): [Release!]!
  services: [Service!]!
  service(id: ID!): Service!
}

type Artifact {
  parallelRunIndex: Int!
  url: String!
  path: String!
}

type Mutation {
  cancelJob(vcsType: String!, username: String!, project: String!, buildNumber: ID!): String
  rerunJobWithSsh(workflowId: String!, jobId: String!): RerunWithSshJob
  cancelWorkflow(workflowId: String!): String
  rerunWorkflow(workflowId: String!, from: String!): String
  logoutUser: String
  approveJob(workflowId: String!, jobId: String!): String
  followProject(vcsType: String!, username: String!, project: String!): String
  unfollowProject(vcsType: String!, username: String!, project: String!): String
  triggerPipeline(vcsType: String!, username: String!, project: String!, branch: String!, parameters: String): TriggeredPipeline
  sendVerificationEmail: String
}

type StepOutput {
  messages: [String!]!
  sequence: Int
  bashCommand: String
  truncated: Boolean
}
`;

export default typeDefs;
