import { Data } from '~/org-settings/graphql/shim/mutators/createContext';
import { CreateContextError } from '~/org-settings/graphql/types';
import {
  APIServiceCreateContextPayloadErrorType as APIErrorType,
  APIServiceCreateContextPayloadError as APIError,
} from '~/org-settings/graphql/shim/apiServiceTypes';
import exceptionLogger from '~/org-settings/backplane/exceptionLogger';

const isAlreadyExistsError = (error: APIError) => {
  if (!error) return false;
  return error.type === APIErrorType.ALREADY_EXISTS;
};

const isValidationError = (error: APIError) => {
  if (!error) return false;
  return error.type === APIErrorType.INVALID_ARGUMENT;
};

const isPermissionDeniedError = (error: APIError) => {
  if (!error) return false;
  return error.type === APIErrorType.PERMISSION_DENIED;
};

const logUnknownError = (data: Data) => {
  exceptionLogger.warn('Uknown error from api-service on createContext', {
    data,
  });
};

const createContext = (error: any) => {
  if (isAlreadyExistsError(error?.graphQLErrors?.[1].extensions)) {
    return {
      errors: [CreateContextError.ALREADY_EXISTS],
      message: error.graphQLErrors[1].message,
    };
  }

  if (isValidationError(error?.graphQLErrors?.[1].extensions)) {
    return {
      errors: [CreateContextError.INVALID_ARGUMENT],
      message: error.graphQLErrors[1].message,
    };
  }

  if (isPermissionDeniedError(error?.graphQLErrors?.[1].extensions)) {
    return {
      errors: [CreateContextError.PERMISSION_DENIED],
      message: error.graphQLErrors[1].message,
    };
  }

  if (error) {
    logUnknownError(error);
    return { errors: [CreateContextError.UNKNOWN] };
  }
  return { errors: [] };
};

export default createContext;
