import { Member } from '../../types';
import { APIMember } from '../fetchers/orgMembers';

type MappedResponse = Member[];

const convertToMember = (apiInvitation: APIMember): Member => ({
  id: apiInvitation.user_id,
  role: apiInvitation.role,
  email: apiInvitation.email,
  username: apiInvitation.username,
  avatarUrl: apiInvitation.avatar_url,
});

const mapOrgMembers = (members: APIMember[]): MappedResponse =>
  members.map(convertToMember);

export default mapOrgMembers;
