import { client } from '@circleci/web-ui-data';

import { APIService } from '~/runners/graphql/shim/fetchers/services';

export interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
  id: string;
}

const fetchService = async ({
  apiKey,
  fetch,
  id,
}: Props): Promise<APIService> => {
  const pathname = `api/private/service/${id}`;
  const response = await client.apiFetcher({ apiKey, fetch, pathname });
  return await response.json();
};

export default fetchService;
