import { client } from '@circleci/web-ui-data';
import { VCSType } from '@circleci/web-ui-data/build/utils/types';
import { useQuery } from '@tanstack/react-query';
import unfetch from 'unfetch';

import apiKey from '~/runners/utils/apiKey';
import windowHasFetch from '~/runners/utils/windowHasFetch';

type UseRunnerTC = (args: { vcsType: VCSType; orgName: string }) => {
  isLoading: boolean;
  error?: unknown;
  runnerEnabled: boolean; // true if org has accepted terms, otherwise false
};

/**
 * Used to determine whether an org has accepted the runner terms and conditions.
 */
const useRunnerTC: UseRunnerTC = ({ vcsType, orgName }) => {
  const fetch = windowHasFetch() ? window.fetch : unfetch;
  const pathname = `/api/v1.1/organization/${vcsType}/${orgName}/settings`;

  const { isLoading, error, data } = useQuery(
    ['terms-conditions', vcsType, orgName],
    async () => {
      const res = await client.apiFetcher({
        apiKey: apiKey(),
        fetch,
        pathname,
      });

      return await res.json();
    },
    { enabled: !!orgName && !!vcsType },
  );

  return {
    isLoading,
    error,
    runnerEnabled: data && data['runner_enabled?'],
  };
};

export default useRunnerTC;
