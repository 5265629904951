import { client } from '@circleci/web-ui-data';

interface Props {
  apiKey: string | undefined;
  fetch: typeof window.fetch;
  vcsType: string;
  orgName: string;
}

export interface APIOrganization {
  id: string;
  name: string;
  slug: string;
}

const fetchOrganization = async ({
  apiKey,
  fetch,
  vcsType,
  orgName,
}: Props): Promise<APIOrganization> => {
  const orgSlug = encodeURIComponent(`${vcsType}/${orgName}`);
  const pathname = `/api/private/organization/${orgSlug}`;
  const response = await client.apiFetcher({ apiKey, fetch, pathname });
  return await response.json();
};

export default fetchOrganization;
