import * as React from 'react';
import { AddProjectsIcon } from '@circleci/compass';
import { Priority, useKBar } from 'kbar';
import router, { useRouter } from 'next/router';
import { useCurrentOrganization } from '~/hooks/useCurrentOrganization';
import { VCSType } from '~/graphql/shim/types';

export default function useOrganizationAction() {
  const { query } = useRouter();
  const vcsType = query.vcsType as VCSType;
  const orgName = (query.username || query.orgName) as string;
  const { organization } = useCurrentOrganization({
    vcsType,
    orgName,
    enabled: Boolean(vcsType && orgName),
  });
  const encodedSlug = encodeURIComponent(organization?.slug || '');
  const { query: kbarQuery } = useKBar();
  const actions = [];
  actions.push(
    {
      id: `currentorganization-Settings`,
      parent: 'currentorganization',
      name: 'Settings',
      shortcut: [],
      keywords: 'settings',
      section: 'Miscellaneous',
      perform: () =>
        router.push(`/settings/organization/${organization?.slug}/overview`),
    },
    {
      id: `currentorganization-Insights`,
      parent: 'currentorganization',
      name: 'Insights',
      shortcut: [],
      keywords: 'insights',
      section: 'Miscellaneous',
      perform: () => router.push(`/insights/${organization?.slug}`),
    },
    {
      id: `currentorganization-Plan`,
      parent: 'currentorganization',
      name: 'Plan',
      shortcut: [],
      keywords: 'plan',
      section: 'Miscellaneous',
      perform: () =>
        router.push(`/settings/plan/${organization?.slug}/overview`),
    },
    {
      id: `currentorganization-Releases`,
      parent: 'currentorganization',
      name: 'Releases',
      shortcut: [],
      keywords: 'releases',
      section: 'Miscellaneous',
      perform: () => router.push(`/releases/${organization?.slug}`),
    },
  );
  if (vcsType === 'circleci') {
    actions.push({
      id: `currentorganization-CreateProject`,
      parent: 'currentorganization',
      name: 'Create Project',
      keywords: 'create project',
      section: 'Miscellaneous',
      icon: <AddProjectsIcon />,
      perform: () => router.push(`/projects/create-project/${encodedSlug}`),
    });
  }

  const rootOrganizationAction = {
    id: 'currentorganization',
    name: organization?.name || '',
    priority: Priority.HIGH,
    shortcut: ['c o'],
    keywords: organization?.name || '',
    section: 'Current Organization',
  };

  kbarQuery.registerActions(
    [rootOrganizationAction, ...actions].filter(Boolean),
  );
}
