import { client } from '@circleci/web-ui-data';

import { APIOrganization } from '~/onboarding/vcs/graphql/shim/types';

const organization = async (
  fetch: any,
  apiKey: string | undefined,
): Promise<APIOrganization[]> => {
  const pathname = '/api/v2/me/collaborations';
  const response = await client.apiFetcher({ apiKey, fetch, pathname });
  return await response.json();
};

export default organization;
