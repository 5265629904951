import { client } from '@circleci/web-ui-data';

export interface APICollaboration {
  name: string;
  avatar_url: string;
  vcs_type: string;
}

const fetchCollaborations = async (
  fetch: any,
  apiKey: string,
): Promise<APICollaboration[]> => {
  const pathname = `/api/v2/me/collaborations`;
  const response = await client.apiFetcher({ apiKey, fetch, pathname });
  return await response.json();
};
export default fetchCollaborations;
