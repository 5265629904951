import { client } from '@circleci/web-ui-data';

type Fetch = typeof fetch;

type RevokeInvitation = {
  fetch: Fetch;
  apiKey?: string;
  invitationId: string;
};

const revokeInvitation = ({
  fetch,
  apiKey,
  invitationId,
}: RevokeInvitation) => {
  const pathname = `/private/access/invitations/${invitationId}`;

  const response = client
    .apiMutator({
      apiKey,
      fetch,
      pathname,
      method: 'DELETE',
      hostname: process.env.NEXT_PUBLIC_CIRCLECI_BFF_HOSTNAME,
    })
    .then((resp) => resp.text());

  return response;
};

export default revokeInvitation;
