import { onError } from '@apollo/client/link/error';
import {
  StatusError,
  isUnauthorized,
} from '@circleci/web-ui-data/build/client/errors';
import { GraphQLError } from 'graphql';

import ExceptionLogger from '~/onboarding/backplane/appExceptionLogger';

/**
 * Identifies the organization or organizationId permissions error
 *
 * The way the current org API is written doesn't allow you to fetch the
 * organization or organisationId for projects you don't have permissions
 * to.
 *
 * To stop polluting Rollbar lets catch and ignore this error until we
 * update this API.
 *
 * @param error
 */
const isOrganizationAuthError = (error: GraphQLError) => {
  const path = error.path && error.path.toString();
  const isOrganizationPath =
    path === 'organization' || path === 'organizationId';
  const isPermissionsError =
    error.message.indexOf('Must have member permission') !== -1;

  return isOrganizationPath && isPermissionsError;
};

/**
 * Identifies the config check 404 Error This 404 is expected when a branch has
 * no config so let's not pollute Rollbar with it
 * @param error
 */
// @ts-ignore
const isBranchConfigCheck = (error) => {
  const isConfigPath = error?.path?.toString() === 'latestConfig';
  const hasConfigCheckErrorMsg =
    error?.message.indexOf('Configuration file not found') !== -1;

  return isConfigPath && hasConfigCheckErrorMsg;
};

// The "branch not found" error occurs when a User tries to set up a project
// via Existing Config (on Project Setup) but has no default branch set
// for their project. A default branch is set on your first commit.
const isBranchNotFound = (error: GraphQLError) => {
  const isFollowPath = error.path?.toString() === 'follow';
  const isBranchNotFoundCheck = error.message === 'Branch not found';

  return isFollowPath && isBranchNotFoundCheck;
};

// When a user is flagged by our security team for being a cypto-miner,
// they will trigger a bunch of permissions errors. We want to filter this
// out because we're (2021-10-28) getting hundreds of these errors per day
// and we don't want to pollute Rollbar with them (they all have a different
// error ID since the repo name is part of the error).
const isBannedUser = (error: GraphQLError) =>
  /Either the org '(.+)' does not exist or you do not have permission to view it\./.test(
    error.message,
  );

const link = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map((error) => {
      const { message, path, originalError } = error;
      const status =
        originalError instanceof StatusError && originalError.status;
      const pathname =
        originalError instanceof StatusError && originalError.pathname;
      const errorMessage = [
        '[GraphQL error]:',
        status && `Status: ${status}`,
        path && `Path: ${path}`,
        !(originalError instanceof StatusError) && `Message: ${message}`,
      ]
        .filter((v) => v)
        .join(' ');

      if (
        isOrganizationAuthError(error) ||
        isUnauthorized(error) ||
        (error.originalError && isUnauthorized(error.originalError)) ||
        isBranchConfigCheck(error) ||
        isBranchConfigCheck(error.originalError) ||
        isBranchNotFound(error) ||
        isBannedUser(error)
      ) {
        return;
      }

      ExceptionLogger.error(errorMessage, {
        error,
        message,
        status,
        pathname,
      });
    });
  }

  if (networkError) {
    ExceptionLogger.error('[Network error]', networkError);
  }
});

export default link;
