import gql from 'graphql-tag';

import apiServiceClient from '~/graphql/apiServiceClient';
import { APIServiceRemoveFromAllowedContextGroupsPayload } from '~/org-settings/graphql/shim/apiServiceTypes';

const mutation = gql`
  mutation DeleteSecurityGroup($input: RemoveFromAllowedContextGroupsInput!) {
    removeFromAllowedContextGroups(input: $input) {
      context {
        id
      }
    }
  }
`;

export interface Data {
  removeFromAllowedContextGroups: APIServiceRemoveFromAllowedContextGroupsPayload;
}

interface Props {
  contextId: string;
  groupId: string;
}

const deleteSecurityGroup = async ({ contextId, groupId }: Props) => {
  const result = await apiServiceClient.mutate<Data>({
    mutation,
    variables: {
      input: {
        contextId,
        groupIds: [groupId],
      },
    },
  });
  return result?.data?.removeFromAllowedContextGroups;
};

export default deleteSecurityGroup;
