import Head from 'next/head';
import * as React from 'react';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/client';
import Modal from 'react-modal';
import { CompassThemeProvider } from '@circleci/compass';
import { PersistOrgId } from '@circleci/react-experiments';
import { client, Provider as GraphQLProvider } from '~/graphql';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Analytics } from '@circleci/analytics';
import { OrgProvider, OrgContext } from '@circleci/web-ui-data';
import scalarQueryParam from '~/utils/queryParams/scalarQueryParam';

import { initPusher } from '~/utils/getPusher';
import MeContext, { MeProvider } from '~/contexts/MeContext';
import HotJarProvider from '~/components/HotJarProvider';
import PageLoading from '~/components/shared-components/PageLoading';
import { renderInviteSlackUnfurl } from '~/engagement-experiments/utils/inviteSlackUnfurlUtil';
import { MaintenancePage } from '@circleci/maintenance';

// Register the exception logger to catch all uncaught exceptions
import ConnectivityToast from '~/components/ConnectivityToast';
import TrackSharedLink from '~/components/shared-components/TrackSharedLink';

// TEMP
import OptimizelyFullStackProvider from '~/components/OptimizelyFullStackProvider';
import { EmailVerification } from '@circleci/web-ui-nav';

import { ProjectPropsProvider } from '~/project-settings/contexts/ProjectPropsContext';
import { ReturnToProvider } from '~/contexts/ReturnToContext';
import RunnerTCContextProvider from '~/runners/contexts/RunnerTCContext';

import '@reach/dialog/styles.css';
import { NextRouter } from 'next/router';
import { UserSurveyProvider } from '~/onboarding/contexts/UserSurveyContext';
import getConfig from 'next/config';

const EmailVerificationWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { me } = React.useContext(MeContext);
  return <EmailVerification me={me}>{children}</EmailVerification>;
};

const ProjectPropsProviderWrapper: React.FC<{
  router: NextRouter;
  children: React.ReactNode;
}> = ({ router, children }) => {
  const isProjectSettings = router.asPath.startsWith('/settings/project');

  if (isProjectSettings) {
    return <ProjectPropsProvider>{children}</ProjectPropsProvider>;
  }

  return <>{children}</>;
};

const RunnerTCProviderWrapper: React.FC<{
  router: NextRouter;
  children: React.ReactNode;
}> = ({ router, children }) => {
  const isRunners = router.asPath.startsWith('/runner');

  if (isRunners) {
    return <RunnerTCContextProvider>{children}</RunnerTCContextProvider>;
  }

  return <>{children}</>;
};

class PageTemplate extends React.Component<any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    initPusher();
    if (document.getElementById('__next')) {
      Modal.setAppElement('#__next');
    }
  }
  render() {
    const { children, router } = this.props;
    const query = router?.query ?? {};
    const vcsType = scalarQueryParam(query.vcsType);
    const orgName = scalarQueryParam(query.orgName || query.username);
    let orgSlug = query.slug || query.orgSlug;

    if (!orgSlug && vcsType && orgName) {
      orgSlug = `${vcsType}/${orgName}`;
    }

    const config = getConfig();
    const captchaKey = config?.publicRuntimeConfig?.captchaKey;

    const skipMe = router.route === '/pipelines/healthcheck';
    // This is for 404 routes so avoid the SSO checks, more pages will be added here
    // like '/create-organization' etc.. as we continue consolidation work
    const skipLoadingOrgInfo =
      ['/dashboard', '/api/login', '/error-page', '/'].includes(router.route) ||
      !orgSlug;

    return (
      <>
        <Head>
          <title>CircleCI</title>
          {renderInviteSlackUnfurl(router)}
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link
            id="apple-touch-favicon"
            key="apple-touch-favicon"
            rel="apple-touch-icon"
            sizes="180x180"
            href="/pipelines/favicons/apple-touch-icon.png"
          />
          <link
            id="large-favicon"
            key="large-favicon"
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/pipelines/favicons/favicon-32x32.png"
          />
          <link
            id="small-favicon"
            key="small-favicon"
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/pipelines/favicons/favicon-16x16.png"
          />
          {captchaKey && (
            <script
              src={`https://www.google.com/recaptcha/api.js?render=${captchaKey}`}
              async
              defer
            ></script>
          )}
        </Head>
        <ApolloHooksProvider client={client}>
          <GraphQLProvider>
            <QueryClientProvider
              client={
                new QueryClient({
                  defaultOptions: { queries: { refetchOnWindowFocus: false } },
                })
              }
              contextSharing={true}
            >
              <Analytics>
                <CompassThemeProvider>
                  <ConnectivityToast />
                  {skipMe ? (
                    children
                  ) : (
                    <ProjectPropsProviderWrapper router={router}>
                      <MeProvider>
                        <UserSurveyProvider>
                          <OrgProvider
                            orgIdOrSlug={encodeURIComponent(orgSlug)}
                            skip={skipLoadingOrgInfo}
                          >
                            <OrgContext.Consumer>
                              {({ loading }) => {
                                if (loading) {
                                  return <PageLoading />;
                                }

                                // TODO: this needs to be enabled
                                // at some point, but currently does not work
                                // because this would error for unauthenticated users viewing OSS pages
                                // if (error) {
                                //   return <Error404 />;
                                // }

                                return (
                                  <HotJarProvider>
                                    <ReturnToProvider>
                                      <OptimizelyFullStackProvider>
                                        <MaintenancePage>
                                          <RunnerTCProviderWrapper
                                            router={router}
                                          >
                                            <EmailVerificationWrapper>
                                              <TrackSharedLink />
                                              {children}
                                            </EmailVerificationWrapper>
                                          </RunnerTCProviderWrapper>
                                        </MaintenancePage>
                                        <PersistOrgId query={router.query} />
                                      </OptimizelyFullStackProvider>
                                    </ReturnToProvider>
                                  </HotJarProvider>
                                );
                              }}
                            </OrgContext.Consumer>
                          </OrgProvider>
                        </UserSurveyProvider>
                      </MeProvider>
                    </ProjectPropsProviderWrapper>
                  )}
                </CompassThemeProvider>
              </Analytics>
            </QueryClientProvider>
          </GraphQLProvider>
        </ApolloHooksProvider>{' '}
      </>
    );
  }
}

export default PageTemplate;
