import { gql } from '@apollo/client';

const typeDefs = gql`
  type Follower {
    login: String
    avatar_url: String
    github_id: Int
  }

  type Repo {
    vcsType: String!
    org: String!
    name: String!
    avatarUrl: String!
    following: Boolean!
    hasFollowers: Boolean!
    admin: Boolean!
    isNew: Boolean!
    language: String
  }

  type UserRole {
    role: String!
  }

  extend type Query {
    buildConfig(
      configYaml: String!
      pipelineValues: [StringKeyVal!]
    ): ProcessedConfig!
    gitHubRepos(page: Int!): [Repo!]!
    userRole: UserRole!
  }

  extend type Mutation {
    setupProject(
      vcsType: String!
      org: String!
      name: String!
      config: String!
      branch: String
      message: String
    ): Boolean
    validateConfig(
      configYaml: String!
      pipelineValues: [StringKeyVal!]
    ): ProcessedConfig!
  }
`;

export default typeDefs;
