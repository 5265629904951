const types = `

enum VCSType {
  github
  bitbucket
}

type Organization {
  vcsType: String!
  name: String!
  avatarUrl: String!
  slug: String
  id: String
}

type ProcessedConfig {
  errors: [ConfigError!]!
  valid: Boolean!
}

type ConfigError {
  message: String!
}

type OrbDetails {
  createdAt: String!
  id: String!
  notes: String
  source: String!
  version: String!
}

input StringKeyVal {
  key: String!
  val: String!
}

extend type Query {
  projectPipelineConfig(pipelineNumber: String!, vcsType: String!, orgName: String!, projectName: String!): Config!
  organizations: [Organization!]!
  orbDetails(name: String!, namespace: String!, version: String!): OrbDetails!
  creditsAvailable(orgId: String!): String
  numberOfUsers(orgId: String!): Int
}

type GraphQLError {
  message: String!
}

extend type Mutation {
  updateConfig(vcsType: String!, org: String!, name: String!, config: String!, branchName: String!, commitMessage: String!, commitSha: String!): Boolean
  updateNewUIOptOut(optOut: String!): Boolean,
}
`;

export default types;
