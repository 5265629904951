import global from '~/onboarding/utils/global';

const getHostname = () => {
  const hostname = global.circleci?.config?.hostname;
  if (!hostname || !hostname.length) {
    throw new Error('CircleCI hostname must be configured.');
  }
  return hostname;
};

export default getHostname;
