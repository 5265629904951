import { client } from '@circleci/web-ui-data';

export type APIAllRolesResponse = APIRole[];

export type APIRole = {
  role: string;
  type: string;
  description: string;
};

type Fetch = typeof fetch;

type FetchAllRolesParams = {
  fetch: Fetch;
  apiKey?: string;
};

const fetchAllRoles = async ({
  fetch,
  apiKey,
}: FetchAllRolesParams): Promise<APIAllRolesResponse> => {
  const pathname = `/private/ciam/all-roles`;
  const response = await client.apiFetcher({
    apiKey,
    fetch,
    pathname,
    urlOptions: {
      hostname: process.env.NEXT_PUBLIC_CIRCLECI_BFF_HOSTNAME,
    },
  });
  return await response.json();
};

export default fetchAllRoles;
