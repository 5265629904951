import {
  APIOrganization,
  Organization,
} from '~/onboarding/vcs/graphql/shim/types';

const organization = ({
  avatar_url,
  name,
  vcs_type,
  slug,
  id,
}: APIOrganization): Organization => ({
  avatarUrl: avatar_url,
  name: name,
  vcsType: vcs_type,
  slug: slug,
  id: id,
});

export default organization;
