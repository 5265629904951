import gql from 'graphql-tag';

import apiServiceClient from '../apiServiceClient';
import { APICreditsAvailable } from '../types';

const query = gql`
  query CreditsAvailable($orgId: String!) {
    plan(orgId: $orgId) {
      id
      creditsAvailable
    }
  }
`;

export interface FetchCreditsAvailable {
  orgId: string;
}

const fetchCreditsAvailable = async ({ orgId }: FetchCreditsAvailable) => {
  const response = await apiServiceClient.query<APICreditsAvailable>({
    query,
    variables: { orgId },
  });

  return response.data;
};

export default fetchCreditsAvailable;
