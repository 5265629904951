import gql from 'graphql-tag';

import apiServiceClient from '../apiServiceClient';
import { APINumberOfUsers } from '../types';

const query = gql`
  query ActiveUsers($orgId: String!) {
    plan(orgId: $orgId) {
      billingPeriods(numPeriods: 1) {
        period {
          start
        }
        metrics {
          activeUsers {
            totalCount
          }
        }
      }
    }
  }
`;

export interface FetchNumberOfUsers {
  orgId: string;
}

const fetchNumberOfUsers = async ({ orgId }: FetchNumberOfUsers) => {
  const response = await apiServiceClient.query<APINumberOfUsers>({
    query,
    variables: { orgId },
  });

  return response.data;
};

export default fetchNumberOfUsers;
