import * as R from 'ramda';
import gql from 'graphql-tag';
import { GraphQLError } from 'graphql';

import { APIServiceContextData } from '~/org-settings/graphql/shim/apiServiceTypes';
import apiServiceClient from '~/graphql/apiServiceClient';

const query = gql`
  query Context($contextId: ID!) {
    context(id: $contextId) {
      id
      resources {
        createdAt
        updatedAt
        truncatedValue
        variable
      }
      groups {
        edges {
          node {
            id
            name
          }
        }
      }
      owner {
        ... on Organization {
          id
          groups {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
      name
    }
  }
`;

const isPermissionError = (errors: readonly GraphQLError[]) =>
  R.all(R.propEq('path', ['context', 'owner', 'groups']), errors);

interface Props {
  contextId: string;
}

export default async (variables: Props): Promise<APIServiceContextData> => {
  const res = await apiServiceClient.query<APIServiceContextData>({
    query,
    variables,
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  });
  if (R.isNil(res.errors)) return res.data;
  if (isPermissionError(res.errors)) return res.data;
  throw new Error(
    `Unhandled errors in response from api-service: "${res.errors}"`,
  );
};
