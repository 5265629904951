import gql from 'graphql-tag';

import apiServiceClient from '~/graphql/apiServiceClient';
import { APIServiceStoreEnvironmentVariablePayload } from '~/org-settings/graphql/shim/apiServiceTypes';

const mutation = gql`
  mutation AddToAllowedContextGroups($input: AddToAllowedContextGroupsInput!) {
    addToAllowedContextGroups(input: $input) {
      context {
        id
      }
    }
  }
`;

export interface Data {
  storeEnvironmentVariable: APIServiceStoreEnvironmentVariablePayload;
}

interface Props {
  contextId: string;
  securityGroupIds: string[];
}

const storeEnvironmentVariable = async ({
  contextId,
  securityGroupIds,
}: Props) => {
  const result = await apiServiceClient.mutate<Data>({
    mutation,
    variables: {
      input: {
        contextId,
        groupIds: securityGroupIds,
      },
    },
  });

  return result.data;
};

export default storeEnvironmentVariable;
