import { APIApplication } from '~/runners/graphql/shim/fetchers/application';
import { Application } from '~/runners/graphql/types';

const mapApplication = ({
  id,
  name,
  description,
}: APIApplication): Application => {
  return {
    id,
    name,
    description: description ?? null,
  };
};

export default mapApplication;
