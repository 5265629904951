import gql from 'graphql-tag';

import apiServiceClient from '~/graphql/apiServiceClient';
import { APIServiceRemoveEnvironmentVariablePayload } from '~/org-settings/graphql/shim/apiServiceTypes';

const mutation = gql`
  mutation DeleteEnvironmentVariable($input: RemoveEnvironmentVariableInput!) {
    removeEnvironmentVariable(input: $input) {
      clientMutationId
    }
  }
`;

export interface Data {
  removeEnvironmentVariable: APIServiceRemoveEnvironmentVariablePayload;
}

interface Props {
  contextId: string;
  name: string;
}

const deleteEnvVariable = async ({ contextId, name: variable }: Props) => {
  const result = await apiServiceClient.mutate<Data>({
    mutation,
    variables: {
      input: {
        contextId,
        variable,
      },
    },
  });
  return result?.data?.removeEnvironmentVariable;
};

export default deleteEnvVariable;
