import { client } from '@circleci/web-ui-data';

interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
  vcsType: string;
  orgName: string;
}

export interface APIOrgSettings {
  feature_flags?: {
    uncertified_orbs_allowed: boolean;
    accepted_runner_terms_of_service: boolean;
    ai_error_summarization?: boolean;
    allow_api_trigger_with_config?: boolean;
    allow_certified_public_orbs?: boolean;
    allow_uncertified_public_orbs?: boolean;
    allow_private_orbs?: boolean;
    bitbucket_workspace_member_is_org_member?: boolean;
  };
  [key: string]: any;
}

export default async ({
  apiKey,
  fetch,
  vcsType,
  orgName,
}: Props): Promise<APIOrgSettings> => {
  const pathname = `/api/v1.1/organization/${vcsType}/${orgName}/settings`;

  const query = {
    'skip-users': 'true',
    'skip-projects': 'true',
  };

  const res = await client.apiFetcher({ apiKey, fetch, pathname, query });
  return res.json();
};
