import { APINumberOfUsers } from '../types';

const mapNumberOfUsers = ({
  plan: {
    billingPeriods: [
      {
        metrics: {
          activeUsers: { totalCount },
        },
      },
    ],
  },
}: APINumberOfUsers): number | null => totalCount;

export default mapNumberOfUsers;
