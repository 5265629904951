import { client } from '@circleci/web-ui-data';
export interface APIOrbStatistics {
  last_30_days_build_count: number;
  last_30_days_project_count: number;
  last_30_days_organization_count: number;
}

export interface APIOrb {
  orb_id: string;
  orb_name: string;
  created_at: string;
  namespace_name: string;
  latest_version_number: string;
  description: string;
  statistics: APIOrbStatistics;
  is_private: boolean;
  hidden: boolean;
}

export interface APIOrbsList {
  orbs: APIOrb[];
  next_page_token?: string;
}

export const exampleOrbs: APIOrb[] = [
  {
    orb_id: '3DE1936B-1769-41F2-BDD6-F937B72C520A',
    namespace_name: 'circleci',
    orb_name: 'foo-orb',
    statistics: {
      last_30_days_build_count: 1,
      last_30_days_project_count: 1,
      last_30_days_organization_count: 1,
    },
    created_at: '2021-07-20T09:07:19Z',
    latest_version_number: '1.2.3',
    description: 'These fooish games are tearing me apart',
    is_private: false,
    hidden: true,
  },
  {
    orb_id: '3DE1936B-1769-41F2-BDD6-F937B72C520A',
    namespace_name: 'circleci',
    orb_name: 'bar-orb',
    statistics: {
      last_30_days_build_count: 1,
      last_30_days_project_count: 1,
      last_30_days_organization_count: 1,
    },
    created_at: '2021-07-20T09:07:19Z',
    latest_version_number: '1.2.3',
    description: 'My favorite orb, bar none',
    is_private: false,
    hidden: true,
  },
];

export const exampleOrbsResponse: APIOrbsList = {
  orbs: exampleOrbs,
  next_page_token: '1234567',
};

const fetchOrbs = async (
  apiKey: string,
  fetch: any,
  orgId: string,
): Promise<APIOrbsList> => {
  const pathname = `/api/private/orb`;
  const query = {
    'org-id': orgId,
  };
  const response = await client.apiFetcher({ apiKey, fetch, pathname, query });
  return await response.json();
};

export default fetchOrbs;
