const typeDefs = `
type UserOrganization {
    name: String!
    avatarUrl: String
    vcsType: String!
}

type ContextSecurityGroup {
    id: ID!
    name: String!
}

type Context {
    id: ID!
    name: String!
    createdAt: String!
    securityGroups: [ContextSecurityGroup!]!
}

type OrbStatistics {
    last30DaysBuildCount: Int!
    last30DaysProjectCount: Int!
    last30DaysOrganizationCount: Int!
}

type Orb {
    orbId: String!
    orbName: String!
    createdAt: String!
    latestVersionNumber: String!
    description: String!
    namespaceName: String!
    statistics: OrbStatistics!
    isPrivate: Boolean!
    hidden: Boolean!
}

type OrbList {
    orbs: [Orb!]!
    nextPageToken: String
}

type OrgSettingsEnvironmentVariable {
    truncatedValue: String!
    name: String!
    createdAt: String!
    updatedAt: String!
}

type ContextDetails {
    environmentVariables: [OrgSettingsEnvironmentVariable!]!
    securityGroups: [ContextSecurityGroup!]!

    name: String!
    availableSecurityGroups: [ContextSecurityGroup!]
}

type Org {
    id: ID!
    vcsType: String!
    orgName: String!
}

type OrgSettings {
    uncertifiedOrbsAllowed: Boolean
    acceptedRunnerTermsOfService: Boolean
    aiErrorSummarization: Boolean
    allowApiTriggerWithConfig: Boolean
    allowCertifiedPublicOrbs: Boolean
    allowUncertifiedPublicOrbs: Boolean
    allowPrivateOrbs: Boolean
    bitbucketWorkspaceMemberIsOrgMember: Boolean
}

enum OrganizationError {
    PERMISSION_DENIED
}

enum CreateContextError {
    ALREADY_EXISTS
    INVALID_ARGUMENT
    PERMISSION_DENIED
    UNKNOWN
}

type CreateContextResponse {
    errors: [CreateContextError!]!
    message: String
}

type DeleteContextResponse {
    # This is an insignificant field. It exists in CreateContextResponse too but
    # we ignore it there. It's defined here only because GraphQL does not allow
    # void types.
    clientMutationId: ID
}

type DeleteSecurityGroupResponseContext {
    id: ID!
}

type DeleteSecurityGroupResponse {
    context: DeleteSecurityGroupResponseContext!
}

type DeleteEnvironmentVariableResponse {
    # This is an insignificant field. It exists in CreateContextResponse too but
    # we ignore it there. It's defined here only because GraphQL does not allow
    # void types.
    clientMutationId: ID
}

enum StoreEnvironmentVariableError {
    ALREADY_EXISTS
    UNKNOWN
    INVALID_ARGUMENT
}

type StoreEnvironmentVariableResponse {
    errors: [StoreEnvironmentVariableError!]!
    message: String
}

enum AddSecurityGroupsError {
    UNKNOWN
}

type AddSecurityGroupsResponse {
    errors: [AddSecurityGroupsError!]!
}

type OrgGithubChecks {
    id: ID!
    installUrl: String
}

type SendInvitationsResponse {
    # This is an insignificant field. It exists in CreateContextResponse too but
    # we ignore it there. It's defined here only because GraphQL does not allow
    # void types.
    clientMutationId: ID
}

type RevokeInvitationResponse {
    message: String
}

type RemoveMemberFromOrgResponse {
    message: String
}

type RemoveMemberFromOrgAndProjectsResponse {
    message: String
}

type ChangeRoleResponse {
    message: String
}

type Invitation {
    id: ID!
    email: String!
    role: String!
    targetType: String!
    targetId: String!
    expiryDate: String!
    inviterId: String!
    createdAt: String!
    isExpired: Boolean!
    isAccepted: Boolean!
    isRevoked: Boolean!
    supersededBy: String
}

type AllInvitations {
    accepted: [Invitation!]!
    expired: [Invitation!]!
    pending: [Invitation!]!
    revoked: [Invitation!]!
}

type Role {
    role: String!
    type: String!
    description: String!
}

type Member {
    id: String!
    email: String
    role: String!
    avatarUrl: String
    username: String
}

type ManageableOrgRoles {
    canManage: [String!]!
}

extend type Query {
    allRoles: [Role!]!
    manageableOrgRoles(orgId: ID!): ManageableOrgRoles
    contexts(orgId: ID!): [Context!]!
    userOrganizations: [UserOrganization!]!
    context(contextId: ID!): ContextDetails!
    org(vcsType: String!, orgName: String!): Org!
    orgSettings(vcsType: String!, orgName: String!): OrgSettings!
    orgGithubChecks(orgId: ID!): OrgGithubChecks!
    orgInvitations(orgId: ID!): AllInvitations!
    orgMembers(orgId: ID!): [Member!]!
    orbs(orgId: ID!, pageSize: Int, pageToken: String): OrbList!
}

extend type Mutation {
    createContext(orgId: ID!, name: String!): CreateContextResponse!
    deleteContext(contextId: ID!): DeleteContextResponse!
    storeEnvironmentVariable(
        contextId: ID!
        value: String
        name: String!
    ): StoreEnvironmentVariableResponse!
    orgSettingsDeleteEnvironmentVariable(
        contextId: ID!
        name: String!
    ): DeleteEnvironmentVariableResponse!
    deleteSecurityGroup(
        contextId: ID!
        groupId: ID!
    ): DeleteSecurityGroupResponse!
    addSecurityGroups(
        contextId: ID!
        securityGroupIds: [ID!]!
    ): AddSecurityGroupsResponse!
    updateAcceptedRunnerTermsOfService(
        vcsType: String!
        orgName: String!
        acceptedRunnerTermsOfService: Boolean!
    ): String
    updateAllowCertifiedPublicOrbs(
        vcsType: String!
        orgName: String!
        allowCertifiedPublicOrbs: Boolean!
    ): String
    updateAllowUncertifiedPublicOrbs(
        vcsType: String!
        orgName: String!
        allowUncertifiedPublicOrbs: Boolean!
    ): String
    updateAllowPrivateOrbs(
        vcsType: String!
        orgName: String!
        allowPrivateOrbs: Boolean!
    ): String
    sendInvitations(
        orgId: String!
        emails: [String!]!
        role: String!
    ): SendInvitationsResponse
    revokeInvitation(
        invitationId: ID!
    ): RevokeInvitationResponse
    removeMemberFromOrg(
        orgId: ID!
        memberId: ID!
    ): RemoveMemberFromOrgResponse
    removeMemberFromOrgAndProjects(
        orgId: ID!
        memberId: ID!
    ): RemoveMemberFromOrgAndProjectsResponse
    changeRole(
        orgId: String!
        usrId: String!
        role: String!
    ): ChangeRoleResponse
}
`;

export default typeDefs;
