import { client } from '@circleci/web-ui-data';

import { VCSType, APIPipeline } from '~/onboarding/vcs/graphql/shim/types';

interface Props {
  fetch: typeof window.fetch;
  apiKey: string | undefined;

  vcsType: VCSType;
  orgName: string;
  projectName: string;
  pipelineNumber: string;
}

const fetchAPIPipelineByNumber = async ({
  fetch,
  apiKey,
  vcsType,
  orgName,
  projectName,
  pipelineNumber,
}: Props) => {
  const pathname = `/api/v2/project/${vcsType}/${orgName}/${projectName}/pipeline/${pipelineNumber}`;
  const res = await client.apiFetcher({ fetch, apiKey, pathname });
  return res.json() as APIPipeline;
};

export default fetchAPIPipelineByNumber;
