import { APIJob } from '~/runners/graphql/shim/fetchers/job';
import { Job } from '~/runners/graphql/types';

const mapProject = ({
  slug,
  name,
  external_url,
}: APIJob['project']): Job['project'] => {
  return {
    slug,
    name,
    externalUrl: external_url,
  };
};

const mapJob = ({ project, name, number, web_url }: APIJob): Job => {
  return {
    project: mapProject(project),
    name,
    number,
    webUrl: web_url,
  };
};

export default mapJob;
