import { client } from '@circleci/web-ui-data';

// https://circleci.com/docs/api/v2/#operation/getPipelineById
export interface APIPipeline {
  id: string;
  errors: [
    {
      type: string;
      message: string;
    },
  ];
  project_slug: string;
  updated_at: string;
  number: number;
  state: string;
  created_at: string;
  trigger: {
    type: string;
    received_at: string;
    actor: {
      login: string;
      avatar_url?: string | null;
    };
  };
  vcs: {
    provider_name: string;
    target_repository_url: string;
    branch: string;
    review_id?: string | null;
    review_url?: string | null;
    revision: string;
    tag?: string | null;
    commit: {
      subject: string;
      body: string;
    };
    origin_repository_url: string;
  };
}

export interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
  id: string;
}

const fetchPipeline = async ({
  apiKey,
  fetch,
  id,
}: Props): Promise<APIPipeline> => {
  const pathname = `/api/v2/pipeline/${id}`;
  const response = await client.apiFetcher({ apiKey, fetch, pathname });
  return await response.json();
};

export default fetchPipeline;
