import { client } from '@circleci/web-ui-data';

import { APIConfig } from '~/onboarding/vcs/graphql/shim/types';

type Fetch = typeof fetch;

const fetchConfig = async (
  fetch: Fetch,
  apiKey: string | undefined,
  pipelineId: string,
): Promise<APIConfig> => {
  const pathname = `/api/v2/pipeline/${pipelineId}/config`;
  const response = await client.apiFetcher({ apiKey, fetch, pathname });
  return response.json();
};

export default fetchConfig;
