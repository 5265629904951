import gql from 'graphql-tag';

import apiServiceClient from '~/graphql/apiServiceClient';
import { APIServiceOrganizationData } from '~/org-settings/graphql/shim/apiServiceTypes';

const query = gql`
  query Contexts($orgId: ID!) {
    organization(id: $orgId) {
      id
      contexts {
        edges {
          node {
            id
            name
            createdAt
            groups {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

interface Props {
  orgId: string;
}

export default async (props: Props): Promise<APIServiceOrganizationData> => {
  const res = await apiServiceClient.query<APIServiceOrganizationData>({
    query,
    variables: props,
    fetchPolicy: 'network-only',
  });
  return res.data;
};
