import { updateAllowCertifiedPublicOrbs } from './mutators/updateAllowCertifiedPublicOrbs';
import { updateAllowUncertifiedPublicOrbs } from './mutators/updateAllowUncertifiedPublicOrbs';
import { updateAllowPrivateOrbs } from './mutators/updateAllowPrivateOrbs';
import updateAcceptedRunnerTermsOfService from './mutators/updateAcceptedRunnerTermsOfService';
import {
  MutationToAddSecurityGroupsArgs,
  MutationToChangeRoleArgs,
  MutationToCreateContextArgs,
  MutationToDeleteContextArgs,
  MutationToDeleteEnvironmentVariableArgs,
  MutationToDeleteSecurityGroupArgs,
  MutationToRemoveMemberFromOrgArgs,
  MutationToRemoveMemberFromOrgAndProjectsArgs,
  MutationToRevokeInvitationArgs,
  MutationToSendInvitationsArgs,
  MutationToStoreEnvironmentVariableArgs,
  MutationToUpdateAllowCertifiedPublicOrbs,
  MutationToUpdateAllowUncertifiedPublicOrbs,
  MutationToUpdateAllowPrivateOrbs,
  QueryToContextArgs,
  QueryToContextsArgs,
  MutationToUpdateAcceptedRunnerTermsOfServiceArgs,
  QueryToManageableOrgRolesArgs,
  QueryToOrbsArgs,
  QueryToOrgGithubChecksArgs,
  QueryToOrgInvitationsArgs,
  QueryToOrgSettingsArgs,
  Resolver,
  DeleteSecurityGroupResponse,
  DeleteEnvironmentVariableResponse,
  DeleteContextResponse,
} from '~/org-settings/graphql/types';

import fetchAPICollaborations from '~/org-settings/graphql/shim/fetchers/collaborations';
import fetchAPIOrgSettings from '~/org-settings/graphql/shim/fetchers/orgSettings';
import fetchAPIServiceContexts from '~/org-settings/graphql/shim/fetchers/contexts';
import fetchAPIServiceContext from '~/org-settings/graphql/shim/fetchers/context';
import fetchAPIServiceOrganizationGithubChecks from '~/org-settings/graphql/shim/fetchers/organizationGithubChecks';
import fetchOrbs from '~/org-settings/graphql/shim/fetchers/orbs';
import fetchOrganizationInvitations from '~/org-settings/graphql/shim/fetchers/organizationInvitations';
import fetchOrganizationMembers from '~/org-settings/graphql/shim/fetchers/orgMembers';
import fetchAllRoles from '~/org-settings/graphql/shim/fetchers/allRoles';
import fetchManageableOrgRoles from '~/org-settings/graphql/shim/fetchers/manageableOrgRoles';

import mapCreateContext from '~/org-settings/graphql/shim/mappers/createContext';
import mapUserOrganizationsToGraphQL from '~/org-settings/graphql/shim/mappers/userOrganizations';
import mapContexts from '~/org-settings/graphql/shim/mappers/contexts';
import mapContext from '~/org-settings/graphql/shim/mappers/context';
import mapStoreEnvironmentVariable from '~/org-settings/graphql/shim/mappers/storeEnvironmentVariable';
import mapOrgSettings from '~/org-settings/graphql/shim/mappers/orgSettings';
import mapOrbs from '~/org-settings/graphql/shim/mappers/orbs';
import mapInvitations from '~/org-settings/graphql/shim/mappers/invitations';
import mapOrgMembers from '~/org-settings/graphql/shim/mappers/orgMembers';
import mapManageableRoles from '~/org-settings/graphql/shim/mappers/manageableRoles';

import createContext from '~/org-settings/graphql/shim/mutators/createContext';
import deleteContext from '~/org-settings/graphql/shim/mutators/deleteContext';
import deleteEnvVariable from '~/org-settings/graphql/shim/mutators/deleteEnvVariable';
import storeEnvironmentVariable from '~/org-settings/graphql/shim/mutators/storeEnvironmentVariable';
import deleteSecurityGroup from '~/org-settings/graphql/shim/mutators/deleteSecurityGroup';
import addSecurityGroups from '~/org-settings/graphql/shim/mutators/addSecurityGroups';
import sendInvitations from '~/org-settings/graphql/shim/mutators/sendInvitations';
import revokeInvitation from '~/org-settings/graphql/shim/mutators/revokeInvitation';
import removeMemberFromOrg from '~/org-settings/graphql/shim/mutators/removeMemberFromOrg';
import removeMemberFromOrgAndProjects from '~/org-settings/graphql/shim/mutators/removeMemberFromOrgAndProjects';
import changeRole from '~/org-settings/graphql/shim/mutators/changeRole';

interface Deps {
  apiKey?: string;
  fetch: typeof window.fetch;
}

const resolvers: Resolver = {
  Query: {
    // eslint-disable-next-line
    contexts: async (_root: any, props: QueryToContextsArgs, _deps: any) => {
      const data = await fetchAPIServiceContexts(props);
      return mapContexts(data) as any;
    },
    context: async (_root: any, props: QueryToContextArgs, _deps: any) => {
      const data = await fetchAPIServiceContext(props);
      return mapContext(data);
    },
    userOrganizations: async (
      _root: any,
      _params: any,
      { fetch, apiKey }: Deps,
    ) => {
      const collaborations = await fetchAPICollaborations(
        fetch,
        apiKey as string,
      );
      return mapUserOrganizationsToGraphQL(collaborations);
    },
    orgSettings: async (
      _root: any,
      { vcsType, orgName }: QueryToOrgSettingsArgs,
      { apiKey, fetch }: Deps,
    ) => {
      const orgSettings = await fetchAPIOrgSettings({
        vcsType,
        orgName,
        apiKey: apiKey as string,
        fetch,
      });
      return mapOrgSettings(orgSettings) as any;
    },
    orgGithubChecks: async (
      _root: any,
      props: QueryToOrgGithubChecksArgs,
      _deps,
    ) => {
      const data = await fetchAPIServiceOrganizationGithubChecks(props);
      return {
        id: props.orgId,
        installUrl: data.externalLinks.githubChecksInstall,
      };
    },
    orbs: async (
      _root: any,
      { orgId }: QueryToOrbsArgs,
      { apiKey, fetch }: Deps,
    ) => {
      const data = await fetchOrbs(apiKey as string, fetch, orgId);
      return mapOrbs(data);
    },
    orgInvitations: async (
      _root: any,
      { orgId }: QueryToOrgInvitationsArgs,
      { apiKey, fetch }: Deps,
    ) => {
      const data = await fetchOrganizationInvitations({ apiKey, fetch, orgId });
      return mapInvitations(data);
    },
    allRoles: async (_root: any, _params: any, { apiKey, fetch }: Deps) =>
      fetchAllRoles({ apiKey, fetch }),
    manageableOrgRoles: async (
      _root: any,
      { orgId }: QueryToManageableOrgRolesArgs,
      { apiKey, fetch }: Deps,
    ) => {
      const data = await fetchManageableOrgRoles({ apiKey, fetch, orgId });
      return mapManageableRoles(data);
    },
    orgMembers: async (
      _root: any,
      { orgId }: QueryToOrgInvitationsArgs,
      { apiKey, fetch }: Deps,
    ) => {
      const data = await fetchOrganizationMembers({ apiKey, fetch, orgId });
      return mapOrgMembers(data);
    },
  },
  Mutation: {
    addSecurityGroups: async (
      _root: any,
      props: MutationToAddSecurityGroupsArgs,
      _deps: any,
    ) => {
      await addSecurityGroups(props);
      return { errors: [] };
    },
    createContext: async (
      _root: any,
      props: MutationToCreateContextArgs,
      _deps: any,
    ) => {
      try {
        await createContext(props);
      } catch (error) {
        return mapCreateContext(error);
      }
      return { errors: [] };
    },
    deleteContext: async (
      _root: any,
      props: MutationToDeleteContextArgs,
      _deps: any,
    ) => deleteContext(props) as DeleteContextResponse,
    storeEnvironmentVariable: async (
      _root: any,
      props: MutationToStoreEnvironmentVariableArgs,
      _deps: any,
    ) => {
      const data = await storeEnvironmentVariable(props);
      return mapStoreEnvironmentVariable(data);
    },
    orgSettingsDeleteEnvironmentVariable: async (
      _root: any,
      props: MutationToDeleteEnvironmentVariableArgs,
      _deps: any,
    ) => (await deleteEnvVariable(props)) as DeleteEnvironmentVariableResponse,
    deleteSecurityGroup: async (
      _root: any,
      props: MutationToDeleteSecurityGroupArgs,
      _deps: any,
    ) => (await deleteSecurityGroup(props)) as DeleteSecurityGroupResponse,
    sendInvitations: async (
      _root: any,
      props: MutationToSendInvitationsArgs,
      deps: Deps,
    ) =>
      await sendInvitations({
        ...deps,
        ...props,
      }),
    revokeInvitation: async (
      _root: any,
      props: MutationToRevokeInvitationArgs,
      deps: Deps,
    ) =>
      await revokeInvitation({
        ...deps,
        ...props,
      }),
    removeMemberFromOrg: async (
      _root: any,
      props: MutationToRemoveMemberFromOrgArgs,
      deps: Deps,
    ) =>
      await removeMemberFromOrg({
        ...deps,
        ...props,
      }),
    removeMemberFromOrgAndProjects: async (
      _root: any,
      props: MutationToRemoveMemberFromOrgAndProjectsArgs,
      deps: Deps,
    ) =>
      await removeMemberFromOrgAndProjects({
        ...deps,
        ...props,
      }),
    changeRole: async (
      _root: any,
      props: MutationToChangeRoleArgs,
      deps: Deps,
    ) =>
      await changeRole({
        ...deps,
        ...props,
      }),
    updateAllowCertifiedPublicOrbs: async (
      _root: any,
      props: MutationToUpdateAllowCertifiedPublicOrbs,
      deps: Deps,
    ) =>
      await updateAllowCertifiedPublicOrbs({
        ...deps,
        ...props,
      }),
    updateAllowUncertifiedPublicOrbs: async (
      _root: any,
      props: MutationToUpdateAllowUncertifiedPublicOrbs,
      deps: Deps,
    ) =>
      await updateAllowUncertifiedPublicOrbs({
        ...deps,
        ...props,
      }),
    updateAllowPrivateOrbs: async (
      _root: any,
      props: MutationToUpdateAllowPrivateOrbs,
      deps: Deps,
    ) =>
      await updateAllowPrivateOrbs({
        ...deps,
        ...props,
      }),
    updateAcceptedRunnerTermsOfService: async (
      _root: any,
      props: MutationToUpdateAcceptedRunnerTermsOfServiceArgs,
      deps: Deps,
    ) =>
      await updateAcceptedRunnerTermsOfService({
        ...deps,
        ...props,
      }),
  },
};

export default resolvers;
